<template>
  <b-collapse id="login-collapse" class="mt-2" v-model="isRolloverVisible">
    <b-card>
      <Login
        class="login-form"
        @credentialsFilled="setCredentialsFilled"
        @beforeLogin="setLoggingIn"
      />

      <!-- <b-card-footer class="collapse-footer">
				<div @click="$router.push('/registracia')" class="w-color-white a-pointer">
					<svg-user-icon class="mr-2" />
					{{ $t('Ešte nemáš konto?') }}<span class="ml-1">{{ $t('Tak si ho vytvor') }}</span>
				</div> 
			</b-card-footer> -->
    </b-card>
  </b-collapse>
</template>

<script>
let areCredentialsFilled = false;

export default {
  components: {
    Login: () => import("/components/Login.vue"),
    // 'svg-user-icon': 	() => import('/assets/icons/user.svg?inline'),
  },

  data() {
    return {
      isRolloverVisible: true,
    };
  },

  created() {
    this.loggingIn = false;
    const isSafari = () =>
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    window.addEventListener(
      "beforeunload",
      (event) => {
        if (this.areCredentialsFilled && !isSafari()) {
          event.preventDefault();
          return (event.returnValue = "");
        }
      },
      { capture: true }
    );
    if (this.$store.getters["auth/isLoggedIn"]) this.$router.push("/profil");
  },

  methods: {
    setCredentialsFilled(areFilled) {
      areCredentialsFilled = areFilled;
    },
    setLoggingIn() {
      this.loggingIn = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!areCredentialsFilled || this.loggingIn) {
      next();
      return;
    }

    const answer = window.confirm(this.$t("unsaved-changes"));

    if (answer) {
      next();
    } else {
      next(false);
    }
  },
};
</script>
<style lang="scss" scoped>
#login-collapse {
  width: 100vw;

  &::v-deep {
    .card {
      border-radius: 0px;
      border: none;
    }

    .card-body {
      padding: 20px 0px 80px 0px;
      margin-bottom: 180px;
      cursor: default;
    }
  }

  .login-form {
    padding: 0px 25px;

    @media only screen and (min-width: 1001px) {
      padding: 0 150px;
    }

    @media only screen and (min-width: 1201px) {
      padding: 0 300px;
    }
  }

  // .collapse-footer {
  // 	width: 100vw;
  // 	height: 107px;
  // 	margin-top: 50px;
  // 	background: #FB2564;
  // 	display: flex;
  // 	align-items: center;
  // 	justify-content: center;
  // 	cursor: default;
  // 	border-radius: 0px;

  // 	span {
  // 		text-decoration: underline;
  // 	}
  // }
}
</style>
